<template>
  <div>
    <b-card title="Workflow Details">
      <b-row>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th style="width: 220px">
                  Group
                </th>
                <td>
                  {{ workflow.group }}
                </td>
              </tr>
              <tr>
                <th>Policy</th>
                <td>
                  {{ workflow.policy }}
                </td>
              </tr>
              <tr>
                <th>Workflow</th>
                <td>
                  {{ workflow.workflow }}
                </td>
              </tr>
              <tr v-if="workflow.firstJobStart">
                <th>First job started on</th>
                <td>
                  {{ workflow.firstJobStart | formatDateTime }}
                  <feather-icon
                    v-b-popover.hover="'The actual date of the first job of this workflow may vary, as only the jobs since the ONEInsight agent installation and max. 5 years back are considered.'"
                    class="ml-1"
                    icon="HelpCircleIcon"
                  />
                </td>
              </tr>
              <tr v-if="workflow.lastJobStart">
                <th>Last job started on</th>
                <td>
                  {{ workflow.lastJobStart | formatDateTime }}
                </td>
              </tr>
              <tr>
                <th>Workflow Job Runs</th>
                <td>
                  {{ workflow.workflowJobCount }}
                  <feather-icon
                    v-b-popover.hover="'The actual number of job of this workflow may vary, as only the jobs since the ONEInsight agent installation and max. 5 years back are considered.'"
                    class="ml-1"
                    icon="HelpCircleIcon"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days']"
            :max-selectable-days="90"
            :timezone="asup.timezone"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="onlyErrors"
            switch
          >
            Errors only
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-card>

    <b-overlay :show="isJobsLoading">
      <b-card
        :header="jobCardHeaderTitle"
      >
        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Status</th>
                <th>Start time</th>
                <th>End time</th>
                <th>Duration</th>
                <th>Succeeded</th>
                <th>Warning</th>
                <th>Failed</th>
                <th>Clone Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <template v-for="(wfJob, wfJobIndex) in sortedJobs">
                <tr :key="wfJobIndex">
                  <td>
                    <div class="row p-0 m-0">
                      <div class="col-6 p-0 m-0">
                        <feather-icon
                          v-if="wfJob.failedClients.length > 0 || wfJob.warningClients.length > 0"
                          :icon="wfJob.showDetails === true ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                          class="cursor-pointer float-left"
                          @click="toggleDetails(wfJob)"
                        />
                      </div>
                      <div class="col-6 p-0 m-0">
                        <JobStatusIcon
                          :status="wfJob.calculatedJobStatus"
                          class="float-left"
                        />
                      </div>
                    </div>
                  </td>
                  <td>{{ wfJob.start | formatDateTimeTz(asup.timezone) }}</td>
                  <td>{{ wfJob.end | formatDateTimeTz(asup.timezone) }}</td>
                  <td>{{ wfJob.duration | formatClrTimeSpan }}</td>
                  <td>{{ wfJob.clientSuccessCount }}</td>
                  <td>{{ wfJob.clientWarningCount }}</td>
                  <td>{{ wfJob.clientFailedCount }}</td>
                  <td>
                    <JobStatusIcon :status="wfJob.calculatedCloneJobStatus" />
                  </td>
                  <td>
                    <b-button
                      size="sm"
                      :to="{ name: 'networker-detail.workflow-job-detail', params: { wfJobId: wfJob.id} }"
                    >
                      Detail
                    </b-button>
                  </td>
                </tr>
                <tr
                  v-if="wfJob.showDetails"
                  :key="`${wfJobIndex}_detail`"
                >
                  <td colspan="4" />
                  <td />
                  <td>
                    <ul>
                      <li
                        v-for="(client, clientIndex) in processClients(wfJobIndex, 'warningClients')"
                        :key="clientIndex"
                      >
                        {{ client.clientName }}
                        <feather-icon
                          v-if="client.notSeenBefore"
                          v-b-popover.hover="'Client did not report a warning on the job before.'"
                          class="ml-1"
                          icon="AlertCircleIcon"
                        />
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li
                        v-for="(client, clientIndex) in processClients(wfJobIndex, 'failedClients')"
                        :key="clientIndex"
                      >
                        {{ client.clientName }}
                        <feather-icon
                          v-if="client.notSeenBefore"
                          v-b-popover.hover="'Client did not report an error on the job before.'"
                          class="ml-1"
                          icon="AlertCircleIcon"
                        />
                      </li>
                    </ul>
                  </td>
                  <td colspan="3" />
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, VBPopover, BFormCheckbox, BForm, BFormGroup, BOverlay, BButton,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'
import NetWorkerService from '@/service/networker.service'
import moment from '@/libs/moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BOverlay,
    BButton,
    DateRangePicker,
    JobStatusIcon,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      workflow: {},
      timeRange: {
        startDate: moment().subtract(7, 'days').toDate(),
        endDate: new Date(),
      },
      isJobsLoading: false,
      jobs: [],
      onlyErrors: false,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
    group() {
      return this.$route.query.group
    },
    policy() {
      return this.$route.query.policy
    },
    workflowName() {
      return this.$route.params.workflowName
    },
    jobCardHeaderTitle() {
      return `Jobs between ${this.$options.filters.formatDate(this.timeRange.startDate)} and ${this.$options.filters.formatDate(this.timeRange.endDate)}`
    },
    statusFilter() {
      if (this.onlyErrors) {
        return ['', 10, 20, 30] // filter for status undefined, active, warning and failed
      }
      return null
    },
    sortedJobs() {
      return this.jobs
        .concat()
        .sort((a, b) => (a.start > b.start ? -1 : 1))
    },
  },
  beforeMount() {
    if (this.$route.query.from && this.$route.query.to) {
      this.timeRange = {
        startDate: moment(this.$route.query.from).toDate(),
        endDate: moment(this.$route.query.to).toDate(),
      }
    }

    if (this.$route.query.onlyErrors === true) {
      this.onlyErrors = true
    }

    // Watch properties after setting timer range from query to avoid loading data too many times
    this.$watch('timeRange', this.loadJobs)
    this.$watch('onlyErrors', this.loadJobs)
  },
  mounted() {
    this.loadData()
    this.loadJobs()
  },
  methods: {
    loadData() {
      NetWorkerService.getWorkflowAsync(this.asup.id, this.group, this.policy, this.workflowName, { disableTenantFilter: true })
        .then(result => {
          this.workflow = result
        })
    },
    loadJobs() {
      this.isJobsLoading = true
      NetWorkerService.getWorkflowJobListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        group: this.group,
        policy: this.policy,
        workflow: this.workflowName,
        status: this.statusFilter,
      }, { disableTenantFilter: true })
        .then(result => {
          result.items.forEach(job => {
            if (job.calculatedJobStatus === 30) {
              // eslint-disable-next-line no-param-reassign
              job.showDetails = true
            }
          })

          this.jobs = result.items
        })
        .finally(() => {
          this.isJobsLoading = false
        })
    },
    toggleDetails(wfJob) {
      // eslint-disable-next-line no-param-reassign
      this.$set(wfJob, 'showDetails', !wfJob.showDetails)
    },
    processClients(wfJobIndex, clientListField) {
      const clients = this.sortedJobs[wfJobIndex][clientListField]

      // sort by string
      const sortedClientNames = clients
        .concat()
        .sort((a, b) => (a > b ? 1 : -1))

      const result = sortedClientNames.map(x => ({
        clientName: x,
      }))

      // check if a client is in list which was not in the list on the day before
      if (wfJobIndex < this.sortedJobs.length - 1) {
        const clientsBefore = this.sortedJobs[wfJobIndex + 1][clientListField]

        for (let i = 0; i < result.length; i += 1) {
          if (!clientsBefore.some(c => c === result[i].clientName)) {
            result[i].notSeenBefore = true
          }
        }
      }

      return result
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
